<template>
    <div class="aboutForPhone">
        <img src="../assets/images/About/u2369_div.png" width="100%"/>
        <!-- 科创信达是谁 -->
        <div class="IOT-view1-content">
            <div class="IOT-view1-content-text1">{{ $t('lang.WhoisKCXD') }}</div>
            <div
                style="width: 60px; border-bottom: 2px solid #95f204; margin-top: -5px"
            ></div>
            <div class="o2s-img">
                <kc-video
                    :url="`https://www.ecofuturefarm.com/resource/videos/科创信达宣传片低清.mp4`"
                    type="mp4"
                    :is-live="false"
                    class="kc-video-dv"
                />
            </div>
            <div class="IOT-view1-content-text2">
                <ul v-if="$i18n.locale == 'zh'">
                    <li style="">
                        <span style="color: #95f204">智能硬件和IoT平台</span>为核心,青岛科创信达科技有限公司是一家以
                        <span style="color: #95f204">智慧养殖数智化解决方案</span>的国家级高新技术企业，经过多年奋斗已经成为国际领先的环控器研发厂商，建设了国内领先的智能硬件电磁实验室，自主研发的
                        <span style="color: #95f204">小科爱牧物联网平台</span>已经累计服务上万家养殖场，目前链接智能终端已经超过了二十万台。
                    </li>
                    <li style="">
                        打造养殖圈的小米生态，始终坚持做"感动人心，价格厚道"的好产品，让猪鸡鸭的生活更简单，
                        <span style="color: #95f204">为农业插上科技的翅膀</span>
                    </li>
                </ul>
                <ul v-else>
                    <li>
                        KeChuangXinDa is a national high-tech enterprise that regards Intelligent hardware and loT platform as the core business and provides DIoT solution of intelligent breeding.
                        After seven years struggling, our company has become an international advanced researcher and manufacturer on climate controllers which has built national leading intelligent hardware electromagnetic laboratory.  The self-developed XiaoKe AI Farm IoT platform has served over ten thousands of farms. At present, more than 200,000 intelligent terminals have been connected.
                    </li>
                    <li>
                        Build the Xiaomi ecosystem of breeding industry. We always insist on making good products with "touching people's hearts and competitive prices", the mission of KeChuangers are to put the wings of science and technology on agriculture.
                    </li>
                </ul>
            </div>
        </div>
        <!-- 我们的文化是什么 -->
        <div class="culture-dv">
            <div class="IOT-view1-content2">
                <div class="IOT-view1-content-text1" style="margin-top: 20px">
                    {{ $t('lang.WhatIsOurCulture.title') }}
                </div>
                <div
                    style="
            width: 60px;
            border-bottom: 2px solid #95f204;
            margin-top: -5px;
          "
                ></div>
            </div>

            <div class="tips tips1">
                <div class="tipsA">{{ $t('lang.WhatIsOurCulture.title1') }}</div>
                <div class="ellipsis">{{ $t('lang.WhatIsOurCulture.msg1') }}</div>
            </div>
            <div class="tips tips2">
                <div class="tipsA">{{ $t('lang.WhatIsOurCulture.title2') }}</div>
                <div class="ellipsis">{{ $t('lang.WhatIsOurCulture.msg2') }}</div>
            </div>
            <div class="tips tips3">
                <div class="tipsA">{{ $t('lang.WhatIsOurCulture.title3') }}</div>
                <div v-if="$i18n.locale == 'zh'">{{ $t('lang.WhatIsOurCulture.msg3') }}</div>
                <el-tooltip effect="dark" placement="top" v-else>
                    <div slot="content" class="ellipsis" style="letter-spacing: -0.7px">{{ $t('lang.WhatIsOurCulture.msg3') }}</div>
                    <div class="ellipsis">{{ $t('lang.WhatIsOurCulture.msg3') }}</div>
                </el-tooltip>
            </div>
            <div class="tips tips4">
                <div class="tipsA">{{ $t('lang.WhatIsOurCulture.title4') }}</div>
                <div v-if="$i18n.locale == 'zh'">{{ $t('lang.WhatIsOurCulture.msg4') }}</div>
                <el-tooltip effect="dark" placement="top" v-else>
                    <div slot="content">{{ $t('lang.WhatIsOurCulture.msg4') }}</div>
                    <div class="ellipsis">{{ $t('lang.WhatIsOurCulture.msg4') }}</div>
                </el-tooltip>
            </div>
            <div class="tips tips5">
                <div class="tipsA" style="white-space: nowrap">{{ $t('lang.WhatIsOurCulture.title5') }}</div>
                <div v-if="$i18n.locale == 'zh'">{{ $t('lang.WhatIsOurCulture.msg5') }}</div>
                <el-tooltip effect="dark" placement="top" v-else>
                    <div slot="content">{{ $t('lang.WhatIsOurCulture.msg5') }}</div>
                    <div class="ellipsis">{{ $t('lang.WhatIsOurCulture.msg5') }}</div>
                </el-tooltip>
            </div>
        </div>
        <!-- 科创之路 -->
        <div class="road-dv">
            <div class="IOT-view1-content2">
                <div class="IOT-view1-content-text1" style="margin-top: 20px; font-size: 18px">
                    {{ $t('lang.WhatIsTheRoadToKechuangXinda') }}
                </div>
                <div
                    style="
            width: 60px;
            border-bottom: 2px solid #95f204;
            margin-top: -5px;
          "
                ></div>
            </div>
            <div class="road-img">
                <img src="../assets/images/About/time.png">
            </div>
        </div>
        <!-- 优势 -->
        <div class="IOT-view1-content mt5">
            <div class="IOT-view1-content-text1">{{ $t('lang.WhatAreOurStrengths') }}</div>
            <div style="width: 80px; border-bottom: 2px solid #95f204"></div>
            <div class="advantage-dv mt20">
                <div
                    class="advantage-dv-item pb10"
                    v-for="item in pagetwoData"
                    :key="item.name"
                >
                    <img style="width: 8vw; height: 8vw" :src="item.src"/><br/>
                    {{ $i18n.locale == 'zh' ? item.name : item.name2 }}
                </div>
            </div>
        </div>
        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import AddressForPhone from "./addressForPhone";
import kcVideo from "./kcVideo";

export default {
    name: "aboutForPhone",
    components: {
        AddressForPhone,
        kcVideo,
    },
    data() {
        return {
            pagetwoData: [
                {name: "一览众山小", name2: "Global", src: require("../assets/images/phone/u251.png")},
                {name: "极致的体验", name2: "Experience", src: require("../assets/images/phone/u254.png")},
                {name: "十分的安全", name2: "Safety", src: require("../assets/images/phone/u257.png")},
                {name: "精细的采集", name2: "Collection", src: require("../assets/images/phone/u260.png")},
                {name: "深度的挖掘", name2: "Analysis", src: require("../assets/images/phone/u263.png")},
                {
                    name: "一触即达农场",
                    name2: "Usability",
                    src: require("../assets/images/phone/u266.png"),
                },
            ],
        };
    },

    methods: {},
};
</script>
<style lang="scss" scoped>
.IOT-view1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #000000;
    color: #fff;
}

.IOT-view1-content2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
}

.IOT-view1-content-text1 {
    font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC",
    sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 22px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 30px;
}

.IOT-view1-content-text2 {
    font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 200%;
    color: #fff;
    padding-right: 20px;
}

.o2s-img {
    width: 90%;
    margin-top: 20px;
}

.culture-dv {
    background-image: url("../assets/images/phone/u1234_div.png");
    background-size: 100% 100%;
    background-position-x: center;
    background-position-y: center;
    width: 100%;
    height: 300px;
    margin-top: 5px;
    position: relative;
}

.tips {
    background: rgba($color: #000000, $alpha: 0.3);
    color: #fff;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    padding: 10px;
    font-size: 0.6rem;
}

.tips1 {
    top: 80px;
    width: 80%;
}

.tips2 {
    top: 120px;
    width: 70%;
}

.tips3 {
    top: 160px;
    width: 60%;
}

.tips4 {
    top: 200px;
    width: 50%;
}

.tips5 {
    top: 240px;
    width: 40%;
}

.tipsA {
    margin-right: 20px;
    border-bottom: solid 2px #95F204;
}

.road-dv {
    background-image: url("../assets/images/phone/u1255_div.png");
    background-size: 100% 100%;
    background-position-x: center;
    background-position-y: center;
    width: 100%;
    height: 200px;
    margin-top: 5px;
    position: relative;
}

.road-img {
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0 20px;

    img {
        width: 100%;
    }
}

.advantage-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.advantage-dv-item {
    text-align: center;
    width: 50px;
    font-size: 0.6rem;

    img {
        width: 60%;
    }
}
</style>
